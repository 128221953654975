import React from 'react';
import '../styles/Banner.css';
import banner from '../assets/banner-01.png';

const Banner: React.FC = () => {
  return (
    <section className="banner">
      <div className="banner-content">
        <div className="text-content">
          <h1>Python para iniciantes com</h1>
          <h2><strong>'Guia Definitivo de Listas em Python'</strong></h2>
          <h3>
            Explore conceitos fundamentais de manipulação de listas em Python e aprenda a otimizar seu código de forma simples e eficaz. <br />
            Este guia é perfeito para iniciantes que querem desenvolver suas habilidades e aprofundar seus conhecimentos no mundo da programação.
          </h3>
          <a href="https://pay.hotmart.com/N95594834X?checkoutMode=10" className="cta-button"aria-label="Comprar o eBook">COMPRAR AGORA</a>
        </div>
        <div className="image-content">
          <img src={banner} alt="Capa do e-book Guia Definitivo sobre Listas, a capa é cinza com o fundo simulando uma folha amassada, a frente primeiro vemos o nome da autora: carol soares, em seguida um desenho de cobra logo do python, abaixo o titulo do livro na cor preta e abaixo um desenho de uma lista desenhada na cor preta com o fundo verde." />
        </div>
      </div>
    </section>
  );
};

export default Banner;
