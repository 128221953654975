import React from 'react';
import '../styles/BookBanner.css';
import capaLivro from '../assets/capa-livro-2.png';

interface BookBannerProps {
  bookTitle: string;
  bookImage: string;
  totalPrice: number;
  installments: number;
}

const BookBanner: React.FC<BookBannerProps> = ({ bookTitle, totalPrice, installments }) => {
  const installmentPrice = totalPrice / installments;

    return (
      <section className="benefits-section">
        <div className="benefits-content">
          <div className="book-image-2">
            <img src={capaLivro} alt="Capa do e-book Guia Definitivo sobre Listas, a capa é cinza com o fundo simulando uma folha amassada, a frente primeiro vemos o nome da autora: carol soares, em seguida um desenho de cobra logo do python, abaixo o titulo do livro na cor preta e abaixo um desenho de uma lista desenhada na cor preta com o fundo verde." />
          </div>
          <div className="book-info">
            <h1>E-Book</h1>
            <h2>{bookTitle}</h2>
            <p className="installments">
              <strong>{installments}x</strong> de <strong>R$ {installmentPrice.toFixed(2)}</strong> sem juros
            </p>
            <p className="total-price">Ou R$ {totalPrice.toFixed(2)} à vista</p>
            <a href="https://pay.hotmart.com/N95594834X?checkoutMode=10" className="buy-now-button" aria-label="Comprar o eBook">COMPRAR AGORA</a>

            </div>
        </div>
      </section>
    );
  };
  
  export default BookBanner;
  