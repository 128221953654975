import React from 'react';
import Header from './components/Header';
import Banner from './components/Banner';
import Benefits from './components/Benefits';
import AboutAuthor from './components/AboutAuthor';
import Footer from './components/Footer';
import BookBanner from './components/BookBanner';
import './App.css';

const App: React.FC = () => {
  return (
    
    <div className="app">
      <Header />
      <Banner />
      <Benefits />
      <AboutAuthor />
      <BookBanner 
        bookTitle="Guia Denifitvo de Listas em Python"
        bookImage="https://link-para-imagem-do-livro.jpg" 
        totalPrice={89.00} 
        installments={10} 
      />
      <Footer />
    </div>
  );
};

export default App;
