import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';
import '../styles/Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-social">
          <h4>Redes Sociais</h4>
          <ul className="social-links">
            <li><a href="https://www.linkedin.com/in/caroline-soares-0ba238107/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /> LinkedIn</a></li>
            <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /> Instagram</a></li>
            <li><a href="https://github.com/carolinesoarws" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} /> GitHub</a></li>
          </ul>
        </div>

        <div className="footer-contact">
          <h4>Suporte e Contato</h4>
          <ul className="contact-links">
            <li><a href="mailto:suporte@livro-guia-sobre-listas.com.br">suporte@livro-guia-sobre-listas.com.br</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Carol Soares Dev. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
