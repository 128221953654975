import React from 'react';
import '../styles/Benefits.css';
import capaLivro from '../assets/capa-livro.png';

const Benefits: React.FC = () => {
  return (
    <section className="benefits-section">
      <div className="benefits-content">
        <div className="benefits-list">
          <div className="benefit-item"> 
            <h3>Desenvolva Habilidades das mais Simples à Avançadas</h3>
            <p>Aprenda técnicas simples à avançadas para otimização e manipulação de listas.</p>
          </div>
          <div className="benefit-item">
            <h3>Melhore a Eficiência do Código</h3>
            <p>Descubra como escrever código Python mais limpo e eficiente.</p>
          </div>
          <div className="benefit-item">
            <h3>Práticas e Exemplos Reais</h3>
            <p>Exemplos práticos e casos de uso para aplicar o conhecimento.</p>
          </div>
          <div className="benefit-item">
            <h3>Material de Referência Completo</h3>
            <p>Um guia completo que você pode consultar sempre que precisar.</p>
          </div>
        </div>
        <div className="book-image">
          <img src={capaLivro} alt="Capa do e-book Guia Definitivo sobre Listas, a capa é cinza com o fundo simulando uma folha amassada, a frente primeiro vemos o nome da autora: carol soares, em seguida um desenho de cobra logo do python, abaixo o titulo do livro na cor preta e abaixo um desenho de uma lista desenhada na cor preta com o fundo verde." />
        </div>
      </div>
    </section>
  );
};


export default Benefits;
