import React from 'react';
import '../styles/AboutAuthor.css';
import autor from '../assets/autor.png';


const AboutAuthor: React.FC = () => {
  return (
    <section className="author-section">
      <div className="author-container">
        <div className="author-text">
          <h2>Sobre a Autora</h2>
          <p>
            Olá! Eu sou Carol Soares, engenheira de software, com uma paixão imensa por compartilhar conhecimento através de documentações completas e fácies de ler. Com mais de 8 anos de experiência no setor de tecnologia, decidi criar o "Guia Definitivo sobre Listas em Python" para capacitar estudantes e  profissionais de TI a otimizar suas habilidades de programação e alcançar novos patamares.
          </p>
          <p>
            Este e-book é fruto de incontáveis horas de pesquisa e prática, construído com um objetivo em mente: oferecer um aprendizado direto e eficaz, que possa ser aplicado imediatamente no dia a dia de qualquer desenvolvedor(a). Espero que cada página inspire você a alcançar resultados surpreendentes em sua carreira!
          </p>
        </div>
        <div className="author-image">
          <img src={autor} alt="Imagem da autora Carol Soares, a imagem é um desenho para representa-la, a personagem no desenho tem cabelo metade preto e metade azul claro, com uma franja cobrindo a testa, e esta usando uma blusa de manga longa preta, esta olhando para frente e sorrindo sem mostrar os dentes." />
        </div>
      </div>
    </section>
  );
};

export default AboutAuthor;
