import React from 'react';
import '../styles/Header.css';

const Navbar: React.FC = () => {
  return (
    <header className="blog-header">
      <div className="blog-title">
        <h1>Carol Soares</h1>
        <p>Dev & Writer</p>
      </div>
      <nav className="blog-navbar">
        <ul className="blog-navbar-links">
          <li><a href="#python-guide">Guia definitivo de listas em Python</a></li>
        </ul>
      </nav>
    </header>

  );
};

export default Navbar;
